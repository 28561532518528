export const rescheduleAppointmentData = {
    "status": 200,
    "message": "Appointment Rescheduled successfully !!"
}
export const appointmentList = {
    "data": {
        "APT_list": [
            {
                "APT_date": "2021-07-29 18:00:00",
                "_id": "60ffb21fd4453f24a0e013df",
                "name": "TEST",
                "mobile": 8147938147,
                "email": "lakshminarayan8147@gmail.com",
                "appointment_Id": "APT20210727-W5S7S",
                "appointment_status": "CONFIRMED",
                "source": "Marketing",
                "order_info": [],
                "Doc_created_date": "2021-07-27 12:43:35",
                "cust_code": "C1002D0006",
                "Store_Address": "New BEL road,Mattikere, Mattikere, Bangalore- 560054"
            },
            {
                "APT_date": "2021-07-29   18:00:00",
                "_id": "60ffb21fd4453f24a0e013df",
                "name": "TEST",
                "mobile": 8147938147,
                "email": "lakshminarayan8147@gmail.com",
                "appointment_Id": "APT20210727-W5S7S",
                "appointment_status": "CONFIRMED",
                "source": "Marketing",
                "order_info": [],
                "Doc_created_date": "2021-07-27   12:43:35",
                "cust_code": "C1002D0006",
                "Store_Address": "New BEL road,Mattikere, Mattikere, Bangalore- 560054"
            }
        ]
    },
    "totalCount": 1,
    "limit": 1,
    "page": 1
};