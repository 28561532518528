<nav class="navbar navbar-expand-lg navbar-transparent">
    <div class="container-fluid">
             <button mat-raised-button class="navbar-toggler justify-content-end" type="button" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
                <span class="navbar-toggler-icon icon-bar"></span>
            </button>
        <div class="collapse navbar-collapse justify-content-between navbar-header px-2 py-1" id="navigation">
            <!-- <div class="navbar-header px-4 py-3 d-flex justify-content-between"> -->
            <div class="lside d-flex">
                <a routerLink="." class="mr-3">
                    <mat-icon>support</mat-icon> Support
                </a>
                <a routerLink=".">
                    <mat-icon>error_outline</mat-icon> Raise Issue
                </a>
            </div>

            <div class="rside d-flex align-items-center">
                <a routerLink="." class="mr-3">
                    <mat-icon>notifications_none</mat-icon>
                </a>
                <a routerLink="." class="mr-3">
                    <mat-icon>search</mat-icon>
                </a>
                <div class="user d-flex align-items-center" mat-button [matMenuTriggerFor]="menu">
                    <h5 class="text-right mb-0 mr-2">John Doe
                        <small class="d-block">City Admin</small>
                    </h5>
                    <img src="/assets/img/faces/marc.jpg" alt="">
                </div>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>Settings</button>
                    <button mat-menu-item (click)="authService.logout()">Logout</button>
                </mat-menu>
            </div>

            <!-- </div> -->
        </div>
    </div>
</nav>
<!-- <div class="col-md-12 mt-4">
    <div class="container-fluid">
        <div class="navbar-header px-4 py-3 d-flex justify-content-between flex-column flex-md-row">
            <div class="lside d-flex">
                <a routerLink="." class="mr-3">
                    <mat-icon>support</mat-icon> Support
                </a>
                <a routerLink=".">
                    <mat-icon>error_outline</mat-icon> Raise Issue
                </a>
            </div>

            <div class="rside d-flex align-items-center">
                <a routerLink="." class="mr-3">
                    <mat-icon>notifications_none</mat-icon>
                </a>
                <a routerLink="." class="mr-3">
                    <mat-icon>search</mat-icon>
                </a>
                <div class="user d-flex align-items-center" mat-button [matMenuTriggerFor]="menu">
                    <h5 class="text-right mb-0 mr-2">John Doe
                        <small class="d-block">City Admin</small>
                    </h5>
                    <img src="/assets/img/faces/marc.jpg" alt="">
                </div>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>Settings</button>
                    <button mat-menu-item (click)="authService.logout()">Logout</button>
                </mat-menu>
            </div>
            
        </div>
    </div>
</div> -->