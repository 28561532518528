import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: any = [
  { path: 'dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
  { path: 'customers-kyc', title: 'Customer KYC', icon: 'person', class: '' },
  {
    path: 'customer-details', title: 'Customer Details', icon: 'content_paste', class: '', dropDown: false,
    routechildren: [
      { path: 'orders', title: 'Orders', class: '' },
      { path: 'payments', title: 'Payments', class: '' },
      { path: 'wallets', title: 'Wallets', class: '' },
      { path: 'changelog', title: 'changelog', class: '' }
    ]
  },
  { path: '/sales', title: 'Sales', icon: 'bubble_chart', class: '' },
  { path: '/update-request', title: 'Update Request', icon: 'unarchive', class: '' },
  // { path: '/user-profile', title: 'User Profile', icon: 'person', class: '' },
  // { path: '/table-list', title: 'Table List', icon: 'content_paste', class: '' },
  // { path: '/typography', title: 'Typography', icon: 'library_books', class: '' },
  // { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '' },
  // { path: '/maps', title: 'Maps', icon: 'location_on', class: '' },
  // { path: '/notifications', title: 'Notifications', icon: 'notifications', class: '' },
  // { path: '/upgrade', title: 'Upgrade to PRO', icon: 'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = [];

  constructor() { }

  ngOnInit() {

    this.menuItems = ROUTES.filter((menuItem: any) => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  menuOpen(menu: any) {
    menu.dropDown = !menu.dropDown;
  }
}
