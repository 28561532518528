<div class="wrapper">
    <div class="header-sec" [ngStyle]="!isIn && {'left': '0px'}">
        <!-- <img class="logo" src="assets/common/logo.png"> -->
        <button type="button" class="navbar-toggle side-nav-toggle pull-left collapsed" #navbarToggler
            (click)="isIn = !isIn;isCollapsedToggle()">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
    </div>
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="./assets/img/sidebar-1.jpg" [ngClass]="isIn ? 'open' : ''">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-4.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>
    </div>
</div>