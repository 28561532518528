export const customerListMock = {
    data: [

        {
            "plant": "Goa"
        },
        {
            "plant": "Kolkata"
        },
        {
            "plant": "Noida"
        },
        {
            "plant": "Bangalore"
        }

    ],
    "Zones": [
        {
            "zone": "East"
        },
        {
            "zone": "West"
        },
        {
            "zone": "North"
        },
        {
            "zone": "South"
        }
    ],
    "Categories": [
        {
            "category": "spec"
        },
        {
            "category": "sunglass"
        },
        {
            "category": "eyeglass"
        },
        {
            "category": "specta"
        }
    ],

    searchs: [
        { "search": "eyeglasses" },
        { "search": "sunglasses" }
    ],
    customerDetails: [
        {
            "storename": "Jangra Opticals",
            "id": "C10024G2575",
            "phone": "+91 8880808080",
            "email": "jangra@gmail.com",
            "plant": "Goa",
            "pmpplan": "Kodak max",
            "username": "Abbinav",
            "customerincharge": "Kunal",
            "ordercount": "1234"
        },
        {
            "storename": "Dia Opticals",
            "id": "D10024G2575",
            "phone": "+91 980808080",
            "email": "dia97@gmail.com",
            "plant": "Kolkata",
            "pmpplan": "max",
            "username": "Arush",
            "customerincharge": "Kaushik",
            "ordercount": "1235"
        },
        {
            "storename": "Eye Opticals",
            "id": "E10024G2575",
            "phone": "+91 9780808080",
            "email": "eye34@gmail.com",
            "plant": "Hydera",
            "pmpplan": "eye max",
            "username": "Rama",
            "customerincharge": "Laxman",
            "ordercount": "1238"
        }
    ],
    "orders": [
        {
            "storename": "Jangra Opticals",
            "id": "C10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "orderno": "378479599",
            "orderdate": "30/12/2020",
            "ordertime": "11:55",
            "currentstatus": "In transit",
            "Wallet": "12516",
            "gkbpayrderno": "or73483743488",
            "drishtiorderno": "DT367448483",
            "custno": "DT367448483"
        },
        {
            "storename": "Faraz Opticals",
            "id": "F10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "orderno": "378479599",
            "orderdate": "30/12/2020",
            "ordertime": "11:55",
            "currentstatus": "In transit",
            "Wallet": "12516",
            "gkbpayrderno": "or73483743488",
            "drishtiorderno": "DT367448483",
            "custno": "DT367448483"
        },
        {
            "storename": "Dia Opticals",
            "id": "D10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "orderno": "378479599",
            "orderdate": "30/12/2020",
            "ordertime": "11:55",
            "currentstatus": "In transit",
            "Wallet": "12516",
            "gkbpayrderno": "or73483743488",
            "drishtiorderno": "DT367448483",
            "custno": "DT367448483"
        }
    ],

    "payments": [
        {
            "storename": "Jangra Opticals",
            "id": "C10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "amount": "3784",
            "drishtiReceiptno": "D456",
            "status": "Success",
            "paymentdate": "23/09/20",
            "TypeofPayment": "online",
            "modeofPayment": "UPI",
            "transactionId": "TR345",
            "Wallet": "123"
        },
        {
            "storename": "Faraz Opticals",
            "id": "F10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "amount": "3784",
            "drishtiReceiptno": "D456",
            "status": "Success",
            "paymentdate": "23/09/20",
            "TypeofPayment": "online",
            "modeofPayment": "UPI",
            "transactionId": "TR345",
            "Wallet": "123"
        },
        {
            "storename": "Dia Opticals",
            "id": "D10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "amount": "3784",
            "drishtiReceiptno": "D456",
            "status": "Success",
            "paymentdate": "23/09/20",
            "TypeofPayment": "online",
            "modeofPayment": "UPI",
            "transactionId": "TR345",
            "Wallet": "123"
        }
    ],
    "wallets": [
        {
            "storename": "Jangra Opticals",
            "id": "C10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "Orderno": "378479599",
            "totalpointused": "122",
            "totalpointadded": "100",
            "totalpointredeemed": "60",
            "totalpointcancel": "30",
            "totalcurrentpoint": "50",

        },
        {
            "storename": "Dia Opticals",
            "id": "D10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "Orderno": "478479599",
            "totalpointused": "232",
            "totalpointadded": "200",
            "totalpointredeemed": "70",
            "totalpointcancel": "80",
            "totalcurrentpoint": "50",

        },
        {
            "storename": "Eye Opticals",
            "id": "E10024G2575",
            "phone": "+91 8880808080",
            "email": "ebinna96@gmail.com",
            "username": "Abbinav",
            "Orderno": "578479599",
            "totalpointused": "322",
            "totalpointadded": "400",
            "totalpointredeemed": "60",
            "totalpointcancel": "30",
            "totalcurrentpoint": "50",

        }

    ],
    "changelogs": [
        {
            "storename": "Jangra Opticals",
            "id": "C10024G2575",
            "phone": "+91 8880808080",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "pincode": "479599",
            "Changedby": "Srinivas",
            "approvedby": "1002Mihir",
        },
        {
            "storename": "Dia Opticals",
            "id": "C10024G2575",
            "phone": "+91 8880808080",
            "email": "binna96@gmail.com",
            "username": "Binav",
            "pincode": "479599",
            "Changedby": "Vivav",
            "approvedby": "1003Vinayak",
        },
        {
            "storename": "Dia Opticals",
            "id": "C10024G2575",
            "phone": "+91 8880808080",
            "email": "binna96@gmail.com",
            "username": "Binav",
            "pincode": "479599",
            "Changedby": "Vivav",
            "approvedby": "1003Vinayak",
        },
    ]
}