import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { urlsMockData } from '../mock/urls.mock';
import { delay } from 'rxjs/operators';

@Injectable()
export class MockHttpCalIInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        for (const element of urlsMockData) {
            if (element.url && request.url.endsWith(element.url) || (element.regex && element.regex.test(request.url))) {
                console.log('Intercepted request: ' + request.url);
                // console.log('element.url---',element.url);
                // console.log('element.data---',element.data);
                return of(new HttpResponse({ status: 200, body: element.data })).pipe(delay(100));
            }
        }
        return next.handle(request);

    }
}