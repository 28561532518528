// import { HmacHttpClient } from './../../utils/app-token-http-client';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as AppUtils from '../utils/app.utils';
import { UserRole } from '../models/userRole';
import { Auth } from '../models/auth';
// import { tokenNotExpired, JwtHelper } from 'angular2-jwt';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import * as Constant from '../constants/app.constants';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ALL_ROUTES } from '../constants/routes.constant';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends Subject<any> {
  authUser: Auth;
  public userRoles: Subject<any> = new Subject<any>();
  isNavBarCollapsed = new Subject<Boolean>();

  constructor(private http: HttpClient,
    // route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    private router: Router) {
    super();
    this.getAuthDetails();
  }

  authenticateUser(payload) {
    return this.http.get(environment.baseUrl + Constant.VERIFY_OTP_LOGIN_URL + payload.mobile + '/' + payload.otp);
  }

  getCurrentRoutes() {
    return ALL_ROUTES[this.authUser.role] ? ALL_ROUTES[this.authUser.role].filter((menuItem: any) => menuItem) : [];
  }

  storeUserData(token, user) {
    this.authUser = new Auth();
    this.authUser = user;
    if (this.authUser.role) {
      this.userRoles.next(this.authUser.role);
    }
    localStorage.setItem(AppUtils.STORAGE_ACCOUNT_TOKEN, token);
    localStorage.setItem(AppUtils.STORAGE_USER_DETAILS, JSON.stringify(this.authUser));
    this.loginSuccess(this.authUser);
  }

  loggedIn(): boolean {
    // const token = localStorage.getItem(AppUtils.STORAGE_ACCOUNT_TOKEN);
    // return tokenNotExpired(AppUtils.STORAGE_ACCOUNT_TOKEN, token);
    return localStorage.getItem(AppUtils.STORAGE_ACCOUNT_TOKEN) !== null;
  }

  loginSuccess(auth: Auth) {
    this.authUser = auth;
    if (auth) {
      auth.authenticated = true;
      super.next(auth);
    }
  }

  isLoggedInUserAdmin() {
    const user = this.authUser;
    if (user.role === Constant.ROLE_ADMIN) { // admin login
      return true;
    } else {
      return false;
    }
  }

  isLoggedInUserCustSupport() {
    const user = this.authUser;
    if (user.role === Constant.ROLE_CUST_SUPPORT) { // cust support login
      return true;
    } else {
      return false;
    }
  }

  getAuthDetails(): Auth {
    this.authUser = (this.authUser) ? this.authUser : new Auth(JSON.parse(localStorage.getItem(AppUtils.STORAGE_USER_DETAILS)));
    return this.authUser;
  }

  set authdetails(value: Auth) {
    this.authUser = value;
  }

  generateOTP(payload) {
    return this.http.post(AppUtils.BACKEND_API_ROOT_URL + Constant.GENERATE_OTP_URL, payload);
  }

  forgotPassword(payload) {
    return this.http.post(AppUtils.BACKEND_API_ROOT_URL + Constant.FORGOT_PWD_URL, payload)
      .pipe(map((response: any) => response.json()));
  }

  resetPassword(resetPassword, resetToken) {
    console.log(resetPassword, resetToken);
    return this.http.post(AppUtils.BACKEND_API_ROOT_URL + Constant.RESET_PWD_URL,
      { 'password': resetPassword, 'otp': resetToken })
      .pipe(map((response: any) => response.json()));
  }

  getUserDetailsByUserId(userId) {
    return this.http.get(AppUtils.BACKEND_API_ROOT_URL + Constant.GET_USER_DETAILS_BY_ID_URL + userId)
      .pipe(map((response: any) => response.json()));
  }


  logout() {
    this.authUser = null;
    if (this.authUser) {
      this.authUser.authenticated = false;
      this.userRoles.complete();
      super.next(this.authUser);
    }
    this.clearStorage();
    this.router.navigate(['/auth']);
    // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
  }

  clearStorage() {
    localStorage.removeItem(AppUtils.STORAGE_ACCOUNT_TOKEN);
    localStorage.removeItem(AppUtils.STORAGE_USER_DETAILS);
  }
}
