import { appointmentList, rescheduleAppointmentData } from "./appointment.mock";
import { customerListMock } from "./customer.mock";
import { generateOTPData, loginVerfiedData } from "./login.mock";
import { customerKycListMock } from "./custmerKyc.mock"

export const urlsMockData = [
    {
        url: 'appointment/generateUser_OTP',
        data: generateOTPData
    },
    {
        regex: /appointment\/generateUser_OTP\/\w+/,
        data: generateOTPData
    },
    {
        regex: /appointment\/verifyUser_Otp\/\w+\/\w+/,
        data: loginVerfiedData
    },
    {
        url: 'appointment/MU_appointments_Info',
        data: appointmentList
    },
    {
        url: 'appointment/create_marketing_users',
        data: generateOTPData
    },
    {
        url: 'get-customer',
        data: customerListMock
    },
    {
        url: 'get-customer-kyc',
        data: customerKycListMock
    },
    {
        regex: /appointment\/Reschedule_appointment_marketing\/\w+/,
        data: rescheduleAppointmentData
    }
]