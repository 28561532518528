import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { CustomersKycComponent } from './pages/admin/customers-kyc/customers-kyc.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    // component: AdminLayoutComponent,
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)    
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      // loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
    }]
  },
  {
    path: 'ecp',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./pages/ecp/ecp.module').then(m => m.EcpModule)
    }]
  },
  // {
  //   path: 'customers-kyc',
  //   loadChildren: () => import('./pages/customers-kyc/customers-kyc.module').then(m => m.CustomersKycModule)
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
