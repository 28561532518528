import { environment } from '../../../environments/environment';

// Local storage keys
export const STORAGE_ACCOUNT_TOKEN = 'gkbpay-account';
export const STORAGE_USER_DETAILS = 'gkbpay-user-details';
export const STORAGE_SECURITY_TOKEN = 'gkbpayApp-security';

// Common http root api
export const BACKEND_API_ROOT_URL: string = environment.baseUrl;



// Customer-Kyc api
export const GET_ALL_ECP_USERS = 'users/getAllEcps';
export const GET_ALL_SM_USERS = 'sm/getAllStoreManagers';
export const GET_COUNT_ECP = 'users/getAllCount';
export const KYC_STATUS = 'users/getList';
export  const UPDATE_KYC ='users/postReasons_superAdmin/'


//customer details
export const GET_ALL_CUSTOMER ='users/getCustomerDetails';

export const GET_ALL_VIEW ='users/getEcpDetail/';
export const GET_ORDER ='users/getOrderDetails/';
export const GET_PAYMENT ='users/getPaymentDetails/';
export const GET_WALLET ='pending';

//order details
export const GET_ALL_ORDER ='users/getAllOrderDetails';

//payment details
export const GET_ALL_PAYMENTS ='payment/getAllPaymentDetails';
//changelog details
export const GET_ALL_CHANGELOG ='request-reason/getAllChangeLogDetails';











export class UrlMatcher {
  public static matches(url: string): boolean {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(url);
  }
}
