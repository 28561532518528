import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
// import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { environment } from 'src/environments/environment';
import { MockHttpCalIInterceptor } from './shared/interceptors/mock-http.interceptor';
import { HttpCalIInterceptor } from './shared/interceptors/http.interceptor';
// import { CustomersKycComponent } from './pages/customers-kyc/customers-kyc.component';
import { MaterialModule } from './material-module';
import { NgxPaginationModule } from 'ngx-pagination';
// import { CustomerDetailsModule } from './pages/customer-details/customer-details.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomersKycModule } from './pages/admin/customers-kyc/customers-kyc.module';
import { ToastrModule } from 'ngx-toastr';

const interceptorClass = environment.enableMock ? MockHttpCalIInterceptor : HttpCalIInterceptor;

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // MaterialModule,
    HttpClientModule,
    ComponentsModule,
    MaterialModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    // CustomerDetailsModule, 
    FormsModule, ReactiveFormsModule, 
    // CustomersKycModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: interceptorClass, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
