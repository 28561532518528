// Local storage keys
export const STORAGE_ACCOUNT_TOKEN = 'gkbpay-account';
export const STORAGE_USER_DETAILS = 'gkbpay-user-details';
export const STORAGE_SECURITY_TOKEN = 'gkbpayApp-security';

/* user role */
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_SUPER_ADMIN = 'SUPER_ADMIN';
export const ROLE_CUST_SUPPORT = 'CUST_SUPPORT';
export const ROLE_ECP_CUSTOMER = 'ECP_CUSTOMER';
export const CITY_ADMIN = 'CITY_ADMIN';
export const ROLE_SPEXMOJO_ADMIN = 'SPEXMOJO_ADMIN'
export const LOOKUP_USER_ROLE = [ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_CUST_SUPPORT, ROLE_ECP_CUSTOMER, ROLE_SPEXMOJO_ADMIN];

// backend url
export const SIGNUP_URL = 'users/register';
export const USER_API = 'users';
export const LOGIN_URL = 'auth';
export const FORGOT_PWD_URL = 'auth/password/forgot';
export const RESET_PWD_URL = 'auth/forgot/reset';
export const USER_PROFILE_CHANGE_PWD = 'users/password/change';
export const CREATE_STUDENT = 'students';
export const UPDATE_USER_PROFILE = 'users/profile/update';
export const FILE_UPLOAD_API = 'files';
export const COLLEGE_FILE_UPLOAD_API = 'files/s3-upload';

/* user - API URL */
export const GET_ALL_USERS_URL = 'users/getList';
export const UPDATE_SEL_USER_URL = 'users/updateUser/';
export const GET_USER_DETAILS_BY_ID_URL = 'users/userDetails/';
export const GENERATE_MODULE_OTP_URL = 'users/generateModuleOtp/';
export const VERIFY_MODULE_OTP_URL = 'users/verifyModuleOtp/';

export const GENERATE_OTP_URL = 'users/generateAndSendOTP';
export const VERIFY_OTP_LOGIN_URL = 'users/verifyOtp/';

export const ACTIVE = 'Active';
export const IN_ACTIVE = 'Inactive';

export const GENDER = ['Male', 'Female', 'Transgender'];
export const QUOTA = ['Government', 'Management'];
export const CATEGORY = ['GM', 'OBC', 'SC/ST', 'Minority'];
export const COURSE = ['1st year', '2nd year'];
export const FIRST_YEAR_COURSE = ['1st year'];
export const SEC_YEAR_COURSE = ['2nd year'];



