declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    dropDown?: boolean;
    routechildren?: any;
}
// export const ROUTES1: any = [
//      // { path: '/user-profile', title: 'User Profile', icon: 'person', class: '' },
//     // { path: '/table-list', title: 'Table List', icon: 'content_paste', class: '' },
//     // { path: '/typography', title: 'Typography', icon: 'library_books', class: '' },
//     // { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '' },
//     // { path: '/maps', title: 'Maps', icon: 'location_on', class: '' },
//     // { path: '/notifications', title: 'Notifications', icon: 'notifications', class: '' },
//     // { path: '/upgrade', title: 'Upgrade to PRO', icon: 'unarchive', class: 'active-pro' },
// ];

export const ECP_CUSTOMER_ROUTES: RouteInfo[] = [
    { path: 'dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
    { path: 'customers-kyc', title: 'Customer KYC', icon: 'person', class: '' },
    {
        path: 'place-order', title: 'Place an Order', icon: 'content_paste', class: '', dropDown: false,
        routechildren: [
            { path: 'lens', title: 'Lens', class: '' },
            { path: 'spectacle', title: 'Spectacle', class: '' },
            { path: 'frame-only', title: 'Frame Only', class: '' },
            { path: 'bulk-orders', title: 'Bulk Orders', class: '' }
        ]
    },
    { path: 'sales', title: 'My Orders', icon: 'bubble_chart', class: '' },
    { path: 'update-request', title: 'Order History', icon: 'unarchive', class: '' },
    { path: 'update-request', title: 'Payments', icon: 'unarchive', class: '' },
    { path: 'update-request', title: 'Invoices', icon: 'unarchive', class: '' },
];

export const CUST_SUPPORT_ROUTES: RouteInfo[] = [
    { path: 'dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
    {
        path: 'customer-details', title: 'Customer Details', icon: 'content_paste', class: '', dropDown: false,
        routechildren: [
            { path: 'orders', title: 'Orders', class: '' },
            { path: 'payments', title: 'Payments', class: '' },
            { path: 'wallets', title: 'Wallets', class: '' },
            { path: 'changelog', title: 'changelog', class: '' }
        ]
    },
    { path: 'sales', title: 'Sales', icon: 'bubble_chart', class: '' },
    { path: 'update-request', title: 'Update Request', icon: 'unarchive', class: '' },

];

export const ALL_ROUTES = {
    CUST_SUPPORT: CUST_SUPPORT_ROUTES,
    ECP_CUSTOMER: ECP_CUSTOMER_ROUTES,
    SUPER_ADMIN: ECP_CUSTOMER_ROUTES
}
