export const customerKycListMock = {
    sm: [

        {
            "storename": "Jangra Opticals",
            "id": "C1002T0038",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "invitedby": "ECP_CUSTOMER",
            "ecpstatus": "Inactive",
            "customerpmp": "No",
            "plant": "2001",
            "gjcustomer": ""
        },
        {
            "storename": "Eye Care",
            "id": "C1002T0039",
            "phone": "+91 9080505050",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "invitedby": "ECP_CUSTOMER",
            "ecpstatus": "Rejected",
            "customerpmp": "No",
            "plant": "2002",
            "gjcustomer": ""
        },
        {
            "storename": "Dia Care",
            "id": "C1002T0040",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "username": "Abbinav",
            "invitedby": "ECP_CUSTOMER",
            "ecpstatus": "Rejected",
            "customerpmp": "No",
            "plant": "2002",
            "gjcustomer": ""
        },
    ],

    ecpgj: [
        {
            "storename": "Jangra Opticals",
            "id": "C1002T0038",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "plant": "2001",
            "username": "Abbinav",
            "dcc" :"C566788898",
            "customerincharge": "Bhuvan",
            "state": "Bihar",
            "city": "Patna",
            "pin": 560103,
            "ll": "28°41’06.1”N 77°21’26.7”E"
        },
        {
            "storename": "Eye Care",
            "id": "E1002T0038",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "plant": "2002",
            "username": "Aarush",
            "dcc" :"E566788898",
            "customerincharge": "Shankar",
            "state": "Goa",
            "city": "Panaji",
            "pin": 876908,
            "ll": "38°41’06.1”N 77°21’26.7”E"
        },
        {
            "storename": "Dia Opticals",
            "id": "D1002T0038",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "plant": "2003",
            "username": "Apurba",
            "dcc" :"D566788898",
            "customerincharge": "Bhupesh",
            "state": "Odisha",
            "city": "BBSR",
            "pin": 566003,
            "ll": "48°41’06.1”N 77°21’26.7”E"
        },

    ],

    ecpdrishti: [
        {
            "storename": "Jangra Opticals",
            "id": "C1002T0038",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "plant": "2001",
            "username": "Abbinav",
            "dcc" :"C566788898",
            "ds" : "Active",
           "Scheme" : "Kodak",
           "totaldeposit" : 20000,
           "ccl" : 40000
        },
        {
            "storename": "Eye Care",
            "id": "E1002T0038",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "plant": "2002",
            "username": "Aarush",
            "dcc" :"E566788898",
            "ds" : "Active",
           "Scheme" : "Kodak",
           "totaldeposit" : 20000,
           "ccl" : 40000
        },
        {
            "storename": "Dia Optics",
            "id": "D1002T0038",
            "phone": "+91 8080505050",
            "email": "abinna96@gmail.com",
            "plant": "2003",
            "username": "Apurba",
            "dcc" :"C566788898",
            "ds" : "Active",
           "Scheme" : "Kodak",
           "totaldeposit" : 20000,
           "ccl" : 40000
        },
        

    ]


}