export const generateOTPData = { "status": 200, "message": "OTP sent successfully" };

export const loginVerfiedData = {
    "status": 200,
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MTAwMDE5NjFhZDJhNjNmMzg1ZjA5MGMiLCJ1c2VyX0lkIjoiTVUyMDIxMDcyNy1KNzJUNCIsInJvbGUiOiJNQVJLRVRJTkdfVVNFUiIsInVzZXJJbmZvIjp7Im5hbWUiOiJMdWNreSIsImVtYWlsIjoibGFrc2htaW5hcmF5YW44MTQ3QGdtYWlsLmNvbSIsIm1vYmlsZSI6ODE0NzkzODE0N30sImlhdCI6MTYyNzM5NTQ5OCwiZXhwIjoxNjI5OTg3NDk4fQ.Hf1IrRLaoYt089Jro8a-wEUZ1RkU16_g8yf-y_BKpfA",
    "user": {
        "name": "Lucky",
        "mobile": 8147938147,
        "email": "lakshminarayan8147@gmail.com",
        "_id": "610001961ad2a63f385f090c",
        "role": "MARKETING_USER",
        "user_Id": "MU20210727-J72T4"
    },
    "message": "OTP Verified successfully for mobile no *******147 "
};